.modal-style{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #3d4450;
    box-shadow: 24;
    padding: 4;
}

.network-icon{
    height: 30px;
    width: 30px;
    border-radius: 10px;
    margin-left: 10px;
}