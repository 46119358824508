.image-card{
    border-radius: 25px;
    margin-right: 5px;
    background-color: grey;
    cursor: pointer;
    display: inline-block;

}
    .image-card-placeholder{
    border-radius: 25px;
    margin-right: 5px;
    background-color: grey;
    cursor: pointer;
    display: inline-block;
    overflow-x: hidden;
}